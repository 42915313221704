import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import GroupGiftForm from "./views/GroupGiftForm";

const App = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:uuid" element=<GroupGiftForm /> />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
