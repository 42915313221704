import * as React from 'react';
import api from 'services/axios';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignaturePad from 'react-signature-canvas'

import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import Dropzone from 'react-dropzone-uploader'

import AnimateButton from 'ui-component/extended/AnimateButton';
import useScriptRef from 'hooks/useScriptRef';


import 'react-dropzone-uploader/dist/styles.css'
import 'index.css'
import nocanvas from 'assets/images/nocanvas.png'
import noframe from 'assets/images/noframe.jpg'
import frameWhite from 'assets/images/frame-white.jpg'
import frameBlack from 'assets/images/frame-black.jpg'
import josias1 from 'assets/images/josias-vorher.png'
import josias2 from 'assets/images/josias-nachher.png'
import danielaSebastian from 'assets/images/daniela-sebastian.png'
import {useParams} from "react-router-dom";


const theme = createTheme();

export default function GroupGiftForm() {
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [validUUID, setValidUUID] = useState(false);
    const [UUIDError, setUUIDError] = useState(null);
    const [alreadySend, setAlreadySend] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const {uuid} = useParams();
    const scriptedRef = useScriptRef();
    const formikRef = React.useRef(null);
    const getUploadParams = ({ meta }) => { return { url: 'https://api.grouply.de/' + {uuid}.uuid + '/files/' } }
    const handleChangeStatus = ({ meta, file }, status) => { if(status === 'done') {
        allFiles.push(meta)
        formikRef.current.validateForm()
    } }
    const handleSigPadChange = () => {
        formikRef.current.validateForm()
    }
    let allFiles = [];
    let sigPad = {}
    const clear = () => {
        sigPad.clear()
    }
    const validateForm = (values, props) => {
        const errors = {};
        if(sigPad.isEmpty()) {
            errors.sigPad = 'Bitte unterschreibe noch in dem Unterschriftenfeld.'
        }

        if(allFiles.length === 0) {
            errors.files = 'Bitte lade zumindest ein Bild des Kindes hoch.'
        }
        return errors;
    };

    const FormValidationSchema = Yup.object().shape({
        member: Yup.string()
                       .max(255, 'Der Name is zu lang. Max. 255 Zeichen')
                       .required('Bitte trage den Namen des Kindes ein')
    })

    useEffect(() => {
        validateUUID();
    }, []);

    const validateUUID = () => {
        setError(null);

        api.get({uuid}.uuid + '/validate')
           .then((response) => {
               if (response.status === 200) {
                   if(response.data.submittedAt) {
                       setResponse(response.data)
                       setAlreadySend(true)
                   } else {
                       setValidUUID(true);
                   }
               } else {
                   setUUIDError({type: 'error', message: 'Irgendwas ist schief gelaufen. Bitte versuche es noch einmal.'});
               }
           })
           .catch((error) => {
               if(error.response.status === 404) {
                   setUUIDError({type: 'error', message: 'Diese URL ist leider ungültig.'});
               } else {
                   setUUIDError({type: 'error', message: error.message});
               }
           });
    }

    if (alreadySend) {
        let frame = '';
        let price = '3,50';
        if(response.canvasFrame == 'noframe') {
            frame = 'ohne Rahmen';
        } else if(response.canvasFrame == 'whiteframe') {
            frame = 'mit weißen Rahmen';
            price = '6,50';
        } else if(response.canvasFrame == 'blackframe') {
            frame = 'mit schwarzen Rahmen';
            price = '6,50';
        }
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Container component="main" maxWidth="sm" sx={{mb: 4}}>
                    <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                        <Typography align="left">
                            Deine Daten sind angekommen. Vielen Dank!<br />
                            Falls du noch etwas ergänzen oder ändern willst, dann schreibt mir doch bitte eine <a href={"mailto:pascal_rossa@gmx.de"}>E-Mail</a> oder Nachricht per <a href={"https://wa.me/491797646379"}>WhatsApp</a>/ <a href={"https://signal.me/#p/+491797646379"}>Signal</a><br />
                        </Typography>
                            {response.canvas ? (<Typography align="left">
                                <br/>Einen Leinwanddruck <b>{frame}</b> werde ich für euch bestellen.
                                <br/>Bitte sendet mir die {price} € dafür per <a href="https://paypal.me/prossa">PayPal via Geld an Freunde senden</a> und gebt den Namen eures Kindes an.<br />
                                Alternativ könnt ihr mir das auch in Bar geben.
                            </Typography>) : null}
                    </Paper>
                </Container>
            </ThemeProvider>
        )
    } else if (!validUUID) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Container component="main" maxWidth="sm" sx={{mb: 4}}>
                    <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                        <Typography align="center">
                            {UUIDError ? UUIDError.message : 'Einen Moment bitte ...' }
                        </Typography>
                    </Paper>
                </Container>
            </ThemeProvider>
        )
    } else {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Container component="main" maxWidth="sm" sx={{mb: 4}}>
                    <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                        <Typography component="h1" variant="h4" align="center">
                            Dein Beitrag zum Abschiedshandtuch für Daniela & Sebastian
                        </Typography>
                        <Typography align="left">
                            Wir wollen Daniela & Sebastian zum Abschied ein Handtuch mit einer Bildercollage und den
                            Unterschriften der Kinder schenken. Es soll etwa so aussehen:<br/>
                            <img src={danielaSebastian} class={'danielaSebastian'} /><br />
                            <br/>
                            Dafür brauchen wir ein Bild und eine Unterschrift von jedem Kind.<br/>
                            Das könnt ihr mir bitte hier schicken. Da die Zeit etwas drängt, bitte möglichst schnell <b>bis Montag 27.06.</b><br/><br/>
                            Wenn ihr das Bild zum Selbstkostenpreis auch für euch als kleines Leinwandbild haben wollt, dann wählt das bitte
                            unten aus. Anstelle des "Danke..." Textes würden wir hier schreiben "Trainingsgruppe Daniela & Sebastian 2018 - 2022".<br/><br/>
                            Bei Fragen, oder wenn etwas nicht klappt, dann schreibt mir doch kurz eine <a
                            href={"mailto:pascal_rossa@gmx.de"}>E-Mail</a>
                            oder per <a href={"https://wa.me/491797646379"}>WhatsApp</a>/ <a
                            href={"https://signal.me/#p/+491797646379"}>Signal</a>
                            <br/><br/>
                        </Typography>

                        <Formik
                            innerRef={formikRef}
                            initialValues={{
                                submit: null,
                                member: '',
                                canvas: 'nocanvas'
                            }}
                            validate={validateForm}
                            validationSchema={FormValidationSchema}
                            onSubmit={async (values, {setErrors, setSubmitting}) => {
                                try {
                                    let data = {
                                        memberName: values.member,
                                        canvas: values.canvas,
                                        signature: sigPad.toDataURL('image/svg+xml'),
                                        files: allFiles
                                    };

                                    if (scriptedRef.current) {
                                        api.post({uuid}.uuid + '/data/', data)
                                           .then((response) => {
                                               if (response.data.errorMessage) {
                                                   setError({type: 'error', message: response.data.errorMessage});
                                               } else {
                                                   setResponse(response.data);
                                                   setAlreadySend(true);
                                                   setSubmitting(false);
                                               }
                                           })
                                           .catch((error) => {
                                               if (error.response.status === 404) {
                                                   setError({type: 'error', message: 'Diese URL ist ungültig'});
                                               } else {
                                                   setError({type: 'error', message: error.response.message});
                                               }
                                           });
                                    }
                                }
                                catch (err) {
                                    console.error(err);
                                    if (scriptedRef.current) {
                                        setErrors({submit: err.message});
                                        setSubmitting(false);
                                    }
                                }
                            }}
                        >
                            {({
                                  errors,
                                  handleChange,
                                  handleSubmit,
                                  isValid,
                                  isSubmitting,
                                  touched,
                                  values}) => (
                                <form noValidate onSubmit={handleSubmit}>
                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                fullWidth
                                                label="Name des Kindes"
                                                margin="normal"
                                                name="member"
                                                type="text"
                                                value={values.member}
                                                onChange={handleChange}
                                            />
                                            {errors.member && touched.member ? (
                                                <div>{errors.member}</div>
                                            ) : null}
                                        </Grid>
                                    </Grid>


                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <br/><br/><br/>
                                            <Typography variant="h6">Foto(s) vom Kind</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={matchDownSM ? 0 : 2}>
                                        <Grid item xs={12} sm={12}>
                                            <Dropzone
                                                getUploadParams={getUploadParams}
                                                onChangeStatus={handleChangeStatus}
                                                SubmitButtonComponent={null}
                                                inputWithFilesContent={'weiteres Foto hinzufügen'}
                                                inputContent={'Foto hier ablegen oder klicken'}
                                                styles={{dropzone: {minHeight: 200, maxHeight: 250}}}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <br/><br/><br/>
                                            <Typography variant="h6">Unterschrift des Kindes</Typography>
                                            <Typography>Ihr könnt hier im Feld unterschreiben</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography>
                                                <b>Tipp:</b> Umso größer der Bildschirm, um so besser lässt sich unterschreiben, daher ggf. ins Querformat wechseln.<br/>
                                            Auch mit einem Stylus (z.B. softes Ende, das manche Kugelschreiber haben) tun sich manche leichter.<br/>
                                                Wenn es etwas verzittert ist, dann kann das auch etwas glattziehen, z.B.
                                            </Typography>
                                            <Grid container alignItems="center" justifyContent="space-between">
                                                <Grid item>
                                                    <img width={'225px'} src={josias1} /><br/>
                                                        <Typography>original</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <img width={'225px'} src={josias2} /><br/>
                                                        <Typography>bearbeitet</Typography>
                                                </Grid>
                                            </Grid>
                                            <SignaturePad
                                                canvasProps={{className: "sigPad", width: 500, height: 300}}
                                                ref={(ref) => { sigPad = ref }}
                                                onEnd={handleSigPadChange}
                                            />
                                            <Button
                                                disableElevation
                                                fullWidth
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={clear}>
                                                Unterschriftfeld leeren
                                            </Button>
                                        </Grid>
                                    </Grid>


                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <br/><br/><br/>
                                            <Typography variant="h6">Wollt ihr das Bild als 20x20cm Leinwand für
                                                euch?</Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container alignItems="top" justifyContent="space-between" rowSpacing={2}>
                                        <Grid item sm={12}>
                                            <label>
                                                <Field style={{"display": "none"}} type="radio" name="canvas"
                                                       value="nocanvas" checked={true} />
                                                <img width="150px" src={nocanvas}/>
                                                <Typography>brauchen wir nicht</Typography>
                                            </label>
                                        </Grid>
                                        <Grid item>
                                            <label>
                                                <Field type="radio" name="canvas" value="noframe"/>
                                                <img width="150px" src={noframe}/>
                                                <Typography>ohne Rahmen<br/>3,50 €</Typography>
                                            </label>
                                        </Grid>
                                        <Grid item>
                                            <label>
                                                <Field type="radio" name="canvas" value="whiteframe"/>
                                                <img width="150px" src={frameWhite}/>
                                                <Typography>mit weißem Rahmen<br/>6,50 €</Typography>
                                            </label>
                                        </Grid>
                                        <Grid item>
                                            <label>
                                                <Field type="radio" name="canvas" value="blackframe"/>
                                                <img width="150px" src={frameBlack}/>
                                                <Typography>mit schwarzem Rahmen<br/>6,50 €</Typography>
                                            </label>
                                        </Grid>
                                    </Grid>


                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <br/><br/><br/>
                                            <Typography>Jetzt klicke bitte noch auf den Button um mir die Daten zu
                                                schicken, dann war es das auch schon. Danke!</Typography>
                                        </Grid>
                                    </Grid>

                                    {errors.submit && (
                                        <Box sx={{mt: 3}}>
                                            <FormHelperText error>{errors.submit}</FormHelperText>
                                        </Box>
                                    )}

                                    {errors.member || errors.files || errors.sigPad ? (
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <br/><br/><br/>
                                                <Typography variant="h6">Huups .. </Typography>
                                                <ul>
                                                    {errors.member ? (
                                                        <li><FormHelperText error>{errors.member}</FormHelperText>
                                                        </li>) : null}
                                                    {errors.files ? (
                                                        <li><FormHelperText error>{errors.files}</FormHelperText>
                                                        </li>) : null}
                                                    {errors.sigPad ? (
                                                        <li><FormHelperText error>{errors.sigPad}</FormHelperText>
                                                        </li>) : null}
                                                </ul>
                                            </Grid>
                                        </Grid>
                                    ) : null}

                                    <Box sx={{mt: 2}}>
                                        <AnimateButton>
                                            <Button
                                                disableElevation
                                                disabled={values.member === '' || !isValid || isSubmitting}
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                            >
                                                Daten abschicken
                                            </Button>
                                        </AnimateButton>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Paper>
                </Container>
            </ThemeProvider>
        );
    }
}